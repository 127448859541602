import { FC } from 'react';
import logo from '../../assets/icons/CPTS__cub_.jpg';
import authLogo from '../../assets/icons/CPTS__all_.jpg';
import ivehteLogo from '../../assets/icons/ivehte.png';

type IvehteCptsLogoProps = {
    height?: number;
    width?: number;
    useAuthenticationLogo?: boolean;
};

const IvehteCptsLogo: FC<IvehteCptsLogoProps> = ({ height, width, useAuthenticationLogo }) => {
    return (
        <>
            <img
                alt="icon"
                style={{ width: width ?? 95, height: height ?? 60 }}
                src={useAuthenticationLogo ? authLogo : logo}
            />
        </>
    );
};

export default IvehteCptsLogo;
