import { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import Resumable from 'resumablejs';
import { toast } from 'react-toastify';
import { USER_KEY } from 'src/services/Token.service';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { baseColors } from 'src/utils/constants/baseStyles';
import { OpenSansSemiBold } from 'src/components/Text/OpenSansSemiBold';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress color="error" size={60} variant="indeterminate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <OpenSansSemiBold
                    style={{ marginLeft: 7, color: baseColors.orange.primary }}
                    fontSize={18}
                >{`${Math.round(props.value)}%`}</OpenSansSemiBold>
            </Box>
        </Box>
    );
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: baseColors.white,
    backgroundColor: baseColors.orange.primary,
    '&:hover': {
        backgroundColor: baseColors.orange.secondary,
    },
    width: '100%',
}));

interface VideoUploaderProps {
    selectedPostData?: any;
    article: any;
    setArticle: any;
    video: any;
    setVideo: any;
    onUpdateSource?: any;
    onUpdateLoading?: any;
    uploadingFile: boolean;
    setUploadingFile: Dispatch<SetStateAction<boolean>>;
}

export const VideoUploader: FC<VideoUploaderProps> = (props) => {
    const { uploadingFile, setUploadingFile, selectedPostData, setArticle, video, onUpdateSource, onUpdateLoading } =
        props;
    const [source, setSource] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const user = sessionStorage.getItem(USER_KEY);
        let token = '';

        if (user) {
            token = JSON.parse(user)['token'];
        }

        let baseUrl = process.env.REACT_APP_BASE_URL;
        let endpoint = `${baseUrl}/api/batchUploads`;

        let instance = new Resumable({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            target: endpoint, // URL backend à appeler pour l’envoi du fichier
            fileType: ['mp4', 'avi', 'webm', 'mkv'],
	    simultaneousUploads: 10,
//	    chunkSize: 10485760,
//	    forceChunkSize: true,

            fileTypeErrorCallback: function (file: any, errorCount: any) {
                alert("C'est un fichier de type non autorisé");
            },
        });

        const btn = document.getElementById('fileInput');

        if (btn) {
            instance.assignBrowse(btn, false);
        }

        instance.on('fileAdded', function (file, event) {
            const obj_url = URL.createObjectURL(file?.file);
            setSource(obj_url);
            onUpdateSource(obj_url);
            setUploadingFile(true);
            instance.upload();
            setArticle((old: any) => ({ ...old, video: { ...old.video, uploadedFileName: file?.file?.name } }));
        });

        instance.on('complete', function () {
            setUploadingFile(false);
            onUpdateLoading(false);
            // toast.success('Video enregistré');
        });

        instance.on('fileProgress', (file: any) => {
            const uploadPercentage = Math.round(file.progress() * 100);
            setProgress(uploadPercentage);
            console.log({ uploadPercentage });
        });

        instance.on('error', function () {
            setUploadingFile(false);
            toast.error('Ouups vidéo non enregistré');
        });

        return () => {
            instance.cancel();
        };
    }, []);

    const btnLabel = uploadingFile ? 'Téléchargement du fichier en cours...' : 'Téléverser un fichier';

    return (
        <div id="fileInput">
            {source ? (
                <div>
                    <video className="VideoInput_video" width={180} height={125} controls src={source} />

                    <div style={{ zIndex: 3, position: 'absolute', top: 220, right: '48%' }}>
                        {uploadingFile && <CircularProgressWithLabel style={{ marginLeft: 10 }} value={progress} />}
                    </div>
                </div>
            ) : selectedPostData?.id > 0 && selectedPostData?.video !== null ? (
                <video width="300" height="200" controls>
                    <source src={`${video}`} type="video/mp4" />
                </video>
            ) : (
                <></>
            )}

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                <ColorButton disabled={uploadingFile} variant="contained" startIcon={<CloudUploadIcon />}>
                    <OpenSansSemiBold
                        style={{ textTransform: 'none' }}
                        color={uploadingFile ? baseColors.black : baseColors.white}
                    >
                        {btnLabel}
                    </OpenSansSemiBold>
                    <VisuallyHiddenInput type="file" />
                </ColorButton>
            </div>
        </div>
    );
};
